import React from 'react'
import { Box, Image, Flex, useThemeUI } from 'theme-ui'
import { Link } from 'gatsby'

import Logo from '../../../../assets/logo.png'
import CovidBadge from '../../../../assets/covid-badge.png'
import BusinessAddress from '../../../../components/helpers/BusinessAddress'
import BusinessPhone from '../../../../components/helpers/BusinessPhone'
import SocialIcons from '../../../../components/helpers/SocialIcons'
import PoweredBy from '../../../../components/Icons/PoweredBy'

const Footer = ({ data }) => {
  const { businessData, allPageDataYaml } = data.data
  const context = useThemeUI()
  const {
    theme: { colors }
  } = context
  const {
    location: { pathname }
  } = data
  const isIndex = pathname === '/'

  const renderSiteMap = () =>
    allPageDataYaml.edges.map(({ node }) => (
      <Box
        sx={{
          paddingX: ['3', '', '', '2', '3'],
          color: 'white',
          textTransform: 'uppercase',
          transition: 'all .3s',
          mb: [3, 3, 0],
          display: 'flex',
          flexWrap: 'wrap',
          '&:hover': {
            color: 'primary',
            transition: 'all .3s'
          }
        }}
      >
        <Link to={node.page.path}>{node.page.title}</Link>
      </Box>
    ))

  const renderBusinessAddress = () => (
    <Box
      sx={{
        mb: 3,
        textAlign: ['center', 'center', '', 'right'],
        width: '100%'
      }}
    >
      <BusinessAddress businessData={businessData} sx={footerLinkStyle} />
    </Box>
  )

  const renderBusinessPhone = (
    <Box
      sx={{
        mb: 3,
        textAlign: ['center', 'center', '', 'right'],
        width: '100%'
      }}
    >
      <BusinessPhone number={businessData.phone} sx={footerLinkStyle} />
    </Box>
  )
  return (
    <>
      <Box sx={{ backgroundColor: 'dark', paddingX: 5, paddingY: 5 }}>
        <Flex
          sx={{
            justifyContent: ['center', 'center', '', 'space-between'],
            flexWrap: 'wrap'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: ['100%', '100%', '', 'auto'],
              maxWidth: ['', '', '', '350px'],
              textAlign: 'center',
              mb: [3, 3, 0]
            }}
          >
            <Link to="/">
              <Image src={Logo} alt={businessData.name} />
            </Link>
            <Image
              sx={{ maxWidth: 200, marginTop: '2rem', marginBottom: '2rem' }}
              src={CovidBadge}
              alt="Covid-19 Guidelines"
            ></Image>
          </Box>
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: ['center', '', '', 'flex-end'],
              width: ['100%', '100%', 'auto']
            }}
          >
            <Flex
              sx={{
                justifyContent: ['center', 'center', 'center'],
                mb: 3,
                width: '100%',
                flexWrap: 'wrap'
              }}
            >
              {renderSiteMap()}

              <Box
                sx={{
                  paddingX: ['3', '', '', '2', '3'],
                  color: 'white',
                  textTransform: 'uppercase',
                  transition: 'all .3s',
                  mb: [3, 3, 0],
                  display: 'flex',
                  flexWrap: 'wrap',
                  paddingY: 2,
                  '&:hover': {
                    color: 'primary',
                    transition: 'all .3s'
                  }
                }}
              >
                <div
                  id="sr-res-root"
                  class="sr-sm sr-dark sr-#324654 inline"
                  style={{
                    textTransform: 'uppercase',
                    cursor: 'pointer'
                  }}
                >
                  Reservations
                </div>
              </Box>
            </Flex>
            {renderBusinessAddress()}
            {renderBusinessPhone}
            <Flex
              sx={{
                justifyContent: ['center', 'center', '', 'flex-end'],
                width: '100%'
              }}
            >
              <SocialIcons></SocialIcons>
            </Flex>
            <Box sx={{ margin: '1rem 0rem' }}>
              {/* <a href='https://slicelife.com/restaurants/ct/norwalk/06854/ponza-italian-restaurant-pizza/menu?utm_campaign=order_now_button&utm_medium=referral&utm_source=ponzaitaliankitchen.com'>
                <img src='https://slicelink-assets-production.imgix.net/partner-buttons/slice-button-medium-red-start-order.png' />
              </a> */}
            </Box>
          </Flex>
        </Flex>

        <Box
          sx={{
            width: ['100%', '100%', 'auto'],
            textAlign: 'center',
            mb: [3, 3, 0]
          }}
        >
          <Box
            sx={{
              maxWidth: 450,
              margin: 'auto auto 2rem',
              '.ctct-form-label, .ctct-gdpr-text,.ctct-form-footer-link': {
                color: 'white !important',
                opacity: '0.7'
              }
            }}
          >
            <div
              className={`ctct-inline-form ${!isIndex && 'hidden'}`}
              data-form-id="01cfe5a8-d3b6-48ea-a375-6c291a517322"
            ></div>
          </Box>
        </Box>
      </Box>

      <Box sx={{ textAlign: 'center', py: 3 }}>
        <Box sx={{ maxWidth: '200px', margin: 'auto' }}>
          <PoweredBy fillColor={colors.primary} />
        </Box>
      </Box>
    </>
  )
}

export default Footer

const footerLinkStyle = {
  color: 'white',
  '&:hover': {
    color: 'primary'
  }
}
