import React from 'react'
import MenuItem from './MenuItem'
import { Button, Box, Heading, Text, Flex } from 'theme-ui'
import { MenuContext } from './MenuContext'
import CloudinaryImage from '../Images/CloudinaryImageJoiner'
import shortid from 'shortid'
import slugify from 'slugify'
import Fade from 'react-reveal/Fade'

const AllIn = ({ menuData, setModalActive, onBackClick }) => {
  const context = React.useContext(MenuContext)

  // Takes Nested sections and and gets the nested child items and child sections
  const splitSectionChildren = section => {
    return section.inventory.reduce(
      (acc, curr) => {
        if ('item' in curr) {
          acc.childItems.push(curr)
        } else if ('section' in curr) {
          acc.childSections.push(curr)
        }
        return acc
      },
      { childItems: [], childSections: [] }
    )
  }

  // Recursively loop through menus and nested menus
  const renderMenu = (menu, nested, idx) => {
    const { section } = menu
    const parsedSection = splitSectionChildren(menu)

    return (
      <Box
        key={shortid.generate()}
        variant={`${context.variantName}.allInContainer`}
        className={`allInContainer ${slugify(section.name, { lower: true })}`}
      >
        {/* header with section name and description */}
        <Fade Bottom>
          <Box variant={`${context.variantName}.menuContainer`} className="menuContainer">
            {section.name ? (
              <Heading variant={`${context.variantName}.menuSectionTitle`} className="menuSectionTitle">
                <Text variant={`${context.variantName}.menuSectionTitleText`} as="span">
                  {section.name}
                </Text>
              </Heading>
            ) : (
              ''
            )}
            {section.desc ? (
              <Text variant={`${context.variantName}.menuSectionDescription`} className="menuSectionDescription">
                {section.desc}
              </Text>
            ) : (
              ''
            )}
            <Flex variant={`${context.variantName}.menuItemsContainer`} className="menuItemsContainer">
              {parsedSection.childItems.map(({ item }, index) => {
                return <MenuItem key={shortid.generate()} type={'default'} item={item} menuItemIndex={index} />
              })}
            </Flex>
          </Box>
        </Fade>

        {/* child sections */}
        {parsedSection.childSections.map((childSection, idx) => renderMenu(childSection, true, idx))}
      </Box>
    )
  }

  return (
    <Box
      variant={`${context.variantName}.allInContainerWrapper`}
      className="allInContainerWrapper"
      style={{
        background: context.options?.background ? context.options.background : ''
      }}
    >
      {onBackClick ? (
        <Button
          variant={`${context.variantName}.backToMenuBtn`}
          className="backToMenuBtn"
          onClick={() => onBackClick()}
        >
          ← Back
        </Button>
      ) : (
        ''
      )}

      {/* {context.options.menuLogo && (
        <Flex
          className='menuLogo'
          sx={{
            maxWidth: '200px',
            margin: '0 auto',
            justifyContent: 'center',
            paddingTop: '1rem',
            img: {
              width: '100%',
              height: '100%',
            },
          }}>
          <CloudinaryImage
            imageBaseUrl={context.businessData.avatar.imageBaseUrl}
            imagePrefix={context.businessData.avatar.imagePrefix}
          />
        </Flex>
      )} */}
      {renderMenu(menuData)}
    </Box>
  )
}

export default AllIn
