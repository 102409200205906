import React from 'react'

// import CovidBadge from '../../../assets/covid-badge.png';
import useApiRequest from '../../../hooks/useAPIRequest'
import Hero from '../../../components/Heros/Hero'
import AboutFeature from '../../../components/About/aboutFeature'
// import { Box, Text, Image, Flex } from '@theme-ui/components';

const About = props => {
  const { error, isLoaded, data } = useApiRequest(props.endpoint[0].url)

  return (
    <div>
      <Hero
        heroData={{
          imageName: 'sites/ponza/ponza-about-hero',
          height: 'calc(85vh - 246.75px)',
          maxWidth: 2000,
          mainTitle: ''
        }}
      ></Hero>
      {/* <Box sx={{ paddingY: [3, 4, 5], paddingX: [2, 4] }}>
        <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
          <Flex sx={{ flexDirection: ['column', 'row'], alignItems: 'center' }}>
            <Box>
              <Image
                sx={{ maxWidth: 200 }}
                src={CovidBadge}
                alt='Covid-19 Guidelines'></Image>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Text as='p' sx={{ lineHeight: 1.75, fontSize: [2], mb: 3 }}>
                Ponza is a safe space dining restaurant and adheres to all
                industry and state-wide safety mandates.
              </Text>
              <Text as='p' sx={{ lineHeight: 1.75, fontSize: [2] }}>
                Strict sanitization protocols are enforced along with the use of
                additional state of the art disinfectant equipment and daily
                cleaning practices.
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box> */}
      <AboutFeature title={`Our Story`} data={data} error={error} isLoaded={isLoaded}></AboutFeature>
    </div>
  )
}

export default About
