import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
export default function Popup() {
  const [popupStory, setPopupStory] = useState(null);
  const [isVisible, setIsVisible] = useState(true); // State to manage popup visibility

  useEffect(() => {
    const fetchStory = async () => {
      fetch(
        'https://gonation.com/api/proxy/v2/businesses/bzn-FondcnQwSQ_Tz1io2ekLSg/aboutArticles'
      )
        .then(response => response.json())
        .then(data =>
          setPopupStory(
            data.general?.find(story => story.tags?.includes('popup'))
          )
        )
        .catch(error => setPopupStory(null));
    };

    fetchStory();
  });

  // Helper function to check if the popup is expired
  const isExpired = expirationDate => {
    const today = new Date();
    const expiryDate = new Date(expirationDate);
    return today > expiryDate;
  };

  const expirationDate = popupStory?.tags
    .find(tag => tag.startsWith('expires-'))
    ?.substring(8);

  if (!popupStory || isExpired(expirationDate) || !isVisible) {
    return null;
  }

  // Close popup function
  const handleClose = () => {
    setIsVisible(false);
  };
  return (
    <Backdrop>
      <PopupContainer>
        <CloseButton onClick={handleClose}>&times;</CloseButton>

        <PopupTitle>{popupStory.title}</PopupTitle>
        {Object.entries(popupStory.links).map(([linkText, linkHref], index) => (
          <div key={index}>
            <PopupLink
              href={linkHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkText}
            </PopupLink>
          </div>
        ))}
      </PopupContainer>
    </Backdrop>
  );
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; // Ensure this is below the PopupContainer's z-index if they differ
`;

// Updated PopupContainer for centering
const PopupContainer = styled.div`
  position: relative;
  width: auto; // Adjust the width as needed
  max-width: 600px; // Adjust the max-width as needed
  margin: auto;
  background-color: white;
  border: 1px solid #ccc;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000; // Ensure this is higher than the Backdrop's z-index
`;

const PopupTitle = styled.h2`
  margin: 0;
  font-size: 20px;
`;

const PopupLink = styled.a`
  text-decoration: none;
  display: block;
  text-align: center;
  background: #019247;
  color: white;
  padding: 0.5rem;
  margin-top: 1rem;
  &:hover {
    text-decoration: underline;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
`;
