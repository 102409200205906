import React from 'react'
import { Box, Flex, Image, Text } from 'theme-ui'
import { MenuContext } from './MenuContext'

const MenuCell = ({ onCellClick, section, inventory, numSections, mode }) => {
  const context = React.useContext(MenuContext)
  const defaultCellBG = 'https://res.cloudinary.com/gonation/gonation.data.prod/default/img-sec-cover-full.png'

  const blockStyle = {
    background: section.imageUrl === defaultCellBG ? '' : `url${section.imageUrl}`,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }

  const businessLogo = `${context.businessData.avatar.imageBaseUrl}/${context.businessData.avatar.imagePrefix}`

  return (
    <Box
      variant={`${context.variantName}.sectionCell`}
      className={`section-cell ${section.name}`}
      onClick={() => {
        return onCellClick({ section, inventory })
      }}
    >
      <Flex variant={`${context.variantName}.menuCell`} className="menuCell" sx={blockStyle}>
        <Box variant={`${context.variantName}.cellImageContainer`} className="cellImageContainer">
          <Box variant={`${context.variantName}.imageFill`} className="imageFill" />
          <Image
            variant={`${context.variantName}.${section.imageUrl !== defaultCellBG ? 'cellImage' : 'cellImageLogo'}`}
            className="cellImage"
            src={section.imageUrl !== defaultCellBG ? section.imageUrl : businessLogo}
            alt="Menu Section Display"
          />
        </Box>
        <Text variant={`${context.variantName}.cellName`} className={`cellName`}>
          {section.name}
        </Text>
      </Flex>
    </Box>
  )
}

export default MenuCell
