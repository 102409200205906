import React, { useEffect } from 'react'
import { Flex, Box, Text } from '@theme-ui/components'
import { FaInstagram } from 'react-icons/fa'
export default function Instagram() {
  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://cdn.lightwidget.com/widgets/lightwidget.js'
    script.async = true
    document.getElementById('scriptWidget').appendChild(script)

    return () => {}
  }, [])

  return (
    <Flex className="scriptWidget" sx={styles.container}>
      <div id={'scriptWidget'}></div>
      <Flex className="logoAndTextContainer" sx={styles.logoAndTextContainer}>
        <FaInstagram />
        <Text as="span">{'Ponza Italian Kitchen & Pizza'}</Text>
      </Flex>
      <Box className="iFrameContainer" sx={styles.lightWidgetContainer}>
        <iframe
          title={'instagramWidget'}
          src={'https://cdn.lightwidget.com/widgets/7fb22802cd3451939d56ffb4e397fd1f.html'}
          scrolling="no"
          allowtransparency="true"
          className="lightwidget-widget"
          style={{ width: '100%', border: '0px', overflow: 'hidden' }}
        />
      </Box>
    </Flex>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  lightWidgetContainer: {
    width: '100%'
  },
  logoAndTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2.5rem'],
    color: 'secondary',
    padding: ['1rem', '', '2rem'],
    svg: {
      marginRight: '1rem'
    },
    span: {
      color: 'primary',
      textDecoration: 'underline'
    }
  }
}

// import React, { useContext } from 'react'
// import GoNationContext from '../../@gonation/gonation-theme-canvas/context/GoNationContext'

// import { graphql, useStaticQuery } from 'gatsby'
// import { Text, Flex } from 'theme-ui'
// import { FaInstagram } from 'react-icons/fa'

// import Thumbnail from './Thumbnail'
// import Heading from '../Styled_UI/Heading'

// const Instagram = () => {
//   const ctx = useContext(GoNationContext)
//   console.log('cts: ', ctx)
//   const instagramURL = ctx.businessData.links.instagram
//   const { name } = ctx.businessData
//   const data = useStaticQuery(
//     graphql`
//       query {
//         allInstagramContent(limit: 5) {
//           edges {
//             node {
//               media_type
//               caption
//               localImage {
//                 childImageSharp {
//                   fluid(maxHeight: 500, maxWidth: 500, quality: 90) {
//                     ...GatsbyImageSharpFluid_withWebp
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     `
//   )
//   const imageNodes = data.allInstagramContent.edges

//   const renderImages = () =>
//     imageNodes.map(({ node }) => <Thumbnail data={node} />)

//   return (
//     <>
//       <Heading
//         sx={{
//           color: 'dark',
//           textAlign: 'center',
//           textDecoration: 'underline',
//           color: 'primary',
//           fontSize: ['4', '5', '6'],
//           mt: [2, 0],
//           path: {
//             color: 'secondary',
//           },
//         }}>
//         <Flex
//           as='a'
//           href={instagramURL}
//           target='_blank'
//           rel='noopener noreferrer'
//           sx={{ alignItems: 'center', justifyContent: 'center' }}>
//           <FaInstagram
//             style={{ marginRight: '1rem', color: 'secondary' }}></FaInstagram>
//           {name}
//         </Flex>
//       </Heading>
//       <Flex sx={{ flexWrap: 'wrap' }}>{renderImages()}</Flex>
//     </>
//   )
// }

// export default Instagram
