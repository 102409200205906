import React from 'react'
import { Flex, Button, Text, Box, Heading, Image } from 'theme-ui'
import { Link } from 'gatsby'

import CloudinaryImage from '../Images/cloudinaryImage'
import cloudinaryOptimizer from '../../helpers/cloudinaryOptimizer'
import getShoutImage from '../../helpers/getShoutImage'
import addHTTP from '../../helpers/addHTTPS'
// change
const sideBySideLarge = ({ withShout, shoutData, reversed }) => {
  if (withShout) {
    const { error, isLoaded, data } = shoutData

    const renderCTAs = () => {
      const buttonNames = Object.keys(data.shout.ctas)
      return buttonNames
        .filter(btn => btn !== 'cta1' && btn !== 'cta2')
        .map(button => {
          return (
            <Text as="a" target="_blank" rel="noopener noreferrer" href={addHTTP(data.shout.ctas[button])}>
              <Button
                sx={{
                  '&:hover': {
                    color: 'text'
                  }
                }}
              >
                {button}
              </Button>
            </Text>
          )
        })
    }

    if (isLoaded && !error) {
      return (
        <Box sx={{ paddingX: [3, 4, 5], paddingY: [2, 3, 4] }}>
          <Flex
            sx={{
              flexWrap: 'wrap',
              alignItems: 'center',
              maxWidth: 1200,
              margin: 'auto'
            }}
          >
            <Box sx={{ width: ['100%', '', '50%'], padding: 3 }}>
              <Box sx={{ maxWidth: 600, margin: 'auto' }}>
                <Heading as="h3" sx={{ fontSize: 6, mb: 3, fontWeight: 300 }}>
                  Recent Shout from {data.shout.business.name}
                </Heading>
                <Text as="p" sx={{ mb: 3 }}>
                  {data.shout.text}
                </Text>
                {data.shout.ctas && renderCTAs()}
              </Box>
            </Box>
            <Box sx={{ width: ['100%', '', '50%'] }}>
              {!data.shout.image.isDefault ? (
                <Image src={`https://res.cloudinary.com/gonation/${data.shout.image.image.cloudinaryId}`}></Image>
              ) : (
                <CloudinaryImage
                  imageName={`sites/ponza/ponza-index-2`}
                  maxWidth={800}
                  width={'100%'}
                ></CloudinaryImage>
              )}
            </Box>
          </Flex>
        </Box>
      )
    } else {
      return <Text>Loading latest announcement.</Text>
    }
  }

  return (
    <Box sx={{ paddingX: [3, 4, 5], paddingY: [2, 3, 4] }}>
      <Flex
        sx={{
          flexWrap: 'wrap',
          alignItems: 'center',
          maxWidth: 1200,
          margin: 'auto'
        }}
      >
        <Box sx={{ width: ['100%', '', '50%'], padding: 3 }}>
          <Box sx={{ maxWidth: 600, margin: 'auto', pl: [0, 3] }}>
            <Heading as="h3" sx={{ fontSize: 6, mb: 3, fontWeight: 300 }}>
              Book A Private Event
            </Heading>
            <Text as="p" sx={{ mb: 3 }}>
              Let us host your private event! View our private events page to see more details and get in touch with us.
            </Text>
            <Link to="/private-events">
              <Button
                sx={{
                  '&:hover': {
                    color: 'text'
                  }
                }}
              >
                Book A Private Event
              </Button>
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            width: ['100%', '', '50%'],
            order: reversed ? '-1' : '1',
            height: 'auto',
            img: {
              objectFit: 'contain!important'
            }
          }}
        >
          <CloudinaryImage
            imageName={`sites/ponza/ponza-index-1`}
            maxWidth={1200}
            width={'100%'}
            height="auto"
            style={{ objectFit: 'contain!important' }}
          ></CloudinaryImage>
        </Box>
      </Flex>
    </Box>
  )
}

export default sideBySideLarge
