import React, { useEffect, useState } from 'react'
import axios from 'axios'
import jsonpAdapter from 'axios-jsonp'
import { Spinner, Flex, Box } from 'theme-ui'
import AllInOnce from './allIn'
import CellsAndAllIn from './cellsAndAllIn'
import TabsView from './tabsView'
import { MenuContext } from './MenuContext'
// import filterMenu from '../../../helpers/filterMenus'
import 'react-image-lightbox/style.css'

export default function Menu({ poweredListID, gonationID, businessData, configData, variantName, options }) {
  const [menuData, setMenuData] = useState({})
  // Fetch the menu data when the template renders.
  useEffect(() => {
    axios({
      url: `https://data.prod.gonation.com/pl/get?profile_id=${gonationID}&powerlistId=${poweredListID}`,
      adapter: jsonpAdapter
    })
      .then(res => {
        // filters out any unwanted sections by creating a new array
        // const menuList = options?.removeSections
        //   ? filterMenu(res.data[0].inventory, options.removeSections)
        //   : res.data
        // const menuList = res.data

        // if (options.removeSections) {
        //   res.data[0].inventory = menuList
        // } // replaces old array with new array
        console.log(res)
        setMenuData(res.data[0]) // sets menu data to state.
      })
      .catch(error => {
        console.log(error)
      })
  }, [gonationID, options, poweredListID])

  const getRenderType = () => {
    switch (menuData.mode) {
      case 'allInOnce':
        return <AllInOnce menuData={menuData} variantName={variantName} mode={menuData.mode} />
      case 'cellsThenAllInOnce':
        return <CellsAndAllIn menuData={menuData} variantName={variantName} mode={menuData.mode} />
      case 'tabs':
        return <TabsView menuData={menuData} variantName={variantName} mode={menuData.mode} />
      case 'cellsThenTabs':
        return <CellsAndAllIn mode={menuData.mode} menuData={menuData} isCellsAndTabs variantName={variantName} />
      default:
        return <h1>Menu Could Not Be Loaded At This Time, please Try Again Later</h1>
    }
  }

  return (
    // react context added instead of prop drilling through menu components
    <MenuContext.Provider value={{ businessData, configData, variantName, options }}>
      <Box
        variant={`${variantName}.innerMenuContainer`}
        className="innerMenuContainer"
        sx={{ width: '100%' }}
        id="innerMenuContainer"
      >
        {menuData && menuData.section ? (
          getRenderType()
        ) : (
          <Flex variant="spinnerContainer">
            <Spinner />
          </Flex>
        )}
      </Box>
    </MenuContext.Provider>
  )
}
