import React from 'react'
import { Box, Text } from 'theme-ui'
import styled from 'styled-components'
import { MenuContext } from './MenuContext'

const MenuTab = ({ onCellClick, section, inventory, activeSection, displayedCellSection, setDisplayedCellSection }) => {
  const context = React.useContext(MenuContext)
  // console.log(displayedCellSection?.section._id === section._id)
  console.log()
  return (
    // <SectionTab
    //   className={`sectionTab has-text-centered ${
    //     activeSection.section === section ? 'tab-is-active' : ''
    //   } ${displayedCellSection.section === section ? 'tab-is-active' : ''} `}
    //   onClick={() => setDisplayedCellSection({ section, inventory })}>
    //   <h2 className='tabMenuName'>{section.name}</h2>
    // </SectionTab>

    <Box
      variant={`${context.variantName}.${
        displayedCellSection?.section._id === section._id ? 'sectionTabActive' : 'sectionTab'
      }`}
      onClick={() => setDisplayedCellSection({ section, inventory })}
    >
      <Text variant={`${context.variantName}.sectionTabName`} as="h3" className="tabMenuName">
        {section.name}
      </Text>
    </Box>
  )
}

export default MenuTab

const SectionTab = styled.div`
  margin: 0 10px;
  min-width: max-content;
  max-width: max-content;
  width: 100%;
  border: 1px solid black;
  padding: 5px 15px;
  cursor: pointer;
  &:hover {
    background: black;
    color: #fff;
    transition: all 0.5s;
  }
`
