import React from 'react'
import { Text, Flex } from '@theme-ui/components'
import { MenuContext } from './MenuContext'

const Price = ({ variants, withDollar, toSide }) => {
  const context = React.useContext(MenuContext)
  return (
    <Flex variant={`${context.variantName}.menuPriceContainer`} className="menuPriceContainer">
      {variants[0].labelTitle ? (
        <Text as="p" variant={`${context.variantName}.menuItemPriceLabel`} className="menuItemPriceLabel">
          {variants[0].labelTitle}
        </Text>
      ) : (
        ''
      )}
      {variants[0].labelTitle && (
        <Text as="p" variant={`${context.variantName}.menuItemPriceLabel`} className="menuItemPriceLabel">
          {variants[0].label}
        </Text>
      )}{' '}
      <Text as="p" variant={`${context.variantName}.menuItemPrice`} className="menuItemPrice">
        {withDollar ? '$' : ''}
        {variants[0].price}
      </Text>
    </Flex>
  )
}

export default Price
