import React from 'react'
import { Flex, Text, Box, Image, Link } from 'theme-ui'
// import dayjs from 'dayjs'
import daysOfWeek from '../../helpers/variables/daysOfTheWeek'
import monthsOfTheYear from '../../helpers/variables/monthsOfTheYearABV'
import moment from 'moment'
import Heading from '../Styled_UI/Heading'
import eventDaysReducer from '../../helpers/eventDaysReducer'

const EventsSmall = ({ data, pageTitle }) => {
  const getEventTime = offer => {
    const { eventDays } = offer
    if (eventDays) {
      return <Text sx={{ textTransform: 'capitalize' }}>{eventDaysReducer(eventDays)}</Text>
    }
  }
  console.log(pageTitle)

  const formatDate = (startISODate, endISODate) => {
    const startDate = new Date(startISODate)
    const endDate = new Date(endISODate)
    const formatTimeObject = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }

    console.log(startDate)

    return `${daysOfWeek[startDate.getDay()]}, ${monthsOfTheYear[startDate.getMonth()]} ${startDate.getDate()}, 

    ${startDate.toLocaleString('en-US', formatTimeObject)} 
    - 
    ${endDate.toLocaleString('en-US', formatTimeObject)}`
  }

  const Event = offer => (
    <Flex
      sx={{
        padding: 3,
        width: ['100%', '100%', '50%'],
        flexWrap: 'wrap'
      }}
    >
      <Box sx={{ width: ['100%', '25%'] }}>
        <Image src={offer.imageurl} alt={offer.name}></Image>
      </Box>

      <Box sx={{ flex: '1', paddingX: 2 }}>
        {pageTitle === 'Events' ? (
          <Box
            sx={{
              width: ['100%', '100%', '100%'],
              marginBottom: 2,
              fontWeight: '500',
              opacity: '0.7',
              textTransform: 'uppercase'
            }}
          >
            <Text>{formatDate(offer.starts, offer.ends)}</Text>
          </Box>
        ) : (
          <Box
            sx={{
              width: ['100%', '100%', '100%'],
              marginBottom: 2,
              fontWeight: '500',
              opacity: '0.7',
              textTransform: 'uppercase'
            }}
          >
            <Text>
              {moment(offer.starts).format('h:mma')} - {moment(offer.ends).format('h:mma')}
            </Text>
          </Box>
        )}
        <Heading sx={{ color: 'dark', fontSize: 3, mb: 0, fontWeight: 600 }}>{offer.name}</Heading>
        <Heading sx={{ color: 'dark', fontSize: 2, my: 2, fontWeight: 500 }}>{getEventTime(offer)}</Heading>
        <Text>{offer.description}</Text>

        {console.log(offer)}

        {offer.ctas ? (
          <Flex sx={styles.eventCTAS} className="eventCTABtns">
            {Object.keys(offer.ctas).map(ctaName => {
              if (offer.ctas[ctaName]) {
                return (
                  <Link sx={styles.eventCTA} href={offer.ctas[ctaName]} target="_blank" rel="noreferrer">
                    {ctaName}
                  </Link>
                )
              }
            })}
          </Flex>
        ) : (
          ''
        )}
      </Box>
    </Flex>
  )
  return (
    <>
      <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
        <Flex sx={{ flexWrap: 'wrap' }}>{data.map(offer => Event(offer))}</Flex>
      </Box>
    </>
  )
}

const styles = {
  eventCTAS: {
    // margin: '1rem 0rem 0rem',
  },
  eventCTA: {
    variant: 'buttons.primary',
    fontSize: ['1rem', '1rem', '1rem', '1rem'],
    textTransform: 'captialize',
    margin: '1rem 1rem 0rem 0rem',
    ':hover': {
      backgroundColor: 'transparent',
      color: 'black'
    }
  }
}

export default EventsSmall
