/** @jsx jsx */
import React from 'react'
import { jsx, Link as ExternalLink } from 'theme-ui'
import { Link } from 'gatsby'
import { Box, Flex, Image } from 'theme-ui'
import { useThemeUI } from 'theme-ui'

import Logo from '../../../../assets/logo.png'
import MobileBottomNav from '../../../../components/Navigations/MobileBottomNav'

const Navigation = ({ data }) => {
  const context = useThemeUI()

  const {
    theme: { colors }
  } = context
  const pageData = data.data.allPageDataYaml.edges
  const {
    name,
    avatar: { imageBaseUrl, imagePrefix }
  } = data.data.businessData
  const avatarURL = `${imageBaseUrl}/${imagePrefix}`

  const renderNavLinks = () =>
    pageData.map(({ node }) => (
      <Link
        key={node.page.path}
        activeStyle={{ color: colors.primary }}
        to={node.page.path}
        sx={{
          color: 'white',
          fontSize: [3, 2, 2],
          display: 'inline-block',
          marginX: 2,
          textTransform: 'uppercase',
          transition: 'all .3s',
          padding: '1rem 0rem',
          '&:hover': {
            color: 'primary',
            transition: 'all .3s'
          }
        }}
      >
        {node.page.title}
      </Link>
    ))

  const center = { justifyContent: 'center' }

  return (
    <>
      {/* DESKTOP NAVIGATION */}
      <Box as="nav" sx={{ background: 'black', padding: 4 }}>
        <Flex sx={center}>
          <Link to="/">
            <Image sx={{ maxWidth: ['200px', '310px'] }} src={Logo || avatarURL} alt={name}></Image>
          </Link>
        </Flex>

        <Box sx={{ display: ['none', '', 'block'], color: 'white' }}>
          <Flex
            sx={{
              ...center,
              mt: 5,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {renderNavLinks()}
            <ExternalLink
              key={'Reservation'}
              href="https://www.toasttab.com/ponzaitaliankitchen"
              target="_blank"
              sx={{
                color: 'white',
                fontSize: [3, 2, 2],
                display: 'inline-block',
                marginX: 2,
                textTransform: 'uppercase',
                transition: 'all .3s',
                padding: '1rem 0rem',
                '&:hover': {
                  color: 'primary',
                  transition: 'all .3s'
                }
              }}
            >
              Online Order
            </ExternalLink>
            <ExternalLink
              key={'Slice'}
              href="https://slicelife.com/restaurants/ct/norwalk/06854/ponza-italian-kitchen-pizza/menu?utm_campaign=order_now_button&utm_medium=referral&utm_source=ponzaitaliankitchen.com"
              target="_blank"
              sx={{
                color: 'white',
                fontSize: [3, 2, 2],
                display: 'inline-block',
                marginX: 2,
                textTransform: 'uppercase',
                transition: 'all .3s',
                padding: '1rem 0rem',
                '&:hover': {
                  color: 'primary',
                  transition: 'all .3s'
                }
              }}
            >
              Slice
            </ExternalLink>
            <div
              id="sr-res-root"
              class="sr-sm sr-dark sr-#324654 inline"
              style={{
                textTransform: 'uppercase',
                cursor: 'pointer'
              }}
            >
              Reservations
            </div>

            {/* <ExternalLink
              key={'Reservation'}
              href='https://www.opentable.com/r/johns-best-pizza-norwalk-reservations-norwalk?restref=1083586&lang=en-US&ot_source=Restaurant%20website'
              target='_blank'
              sx={{
                color: 'white',
                fontSize: [3, 2, 2],
                display: 'inline-block',
                marginX: 2,
                textTransform: 'uppercase',
                transition: 'all .3s',
                padding: '1rem 0rem',
                '&:hover': {
                  color: 'primary',
                  transition: 'all .3s',
                },
              }}
            >
              Make a Reservation
            </ExternalLink> */}

            {/* <ExternalLink
              key={'Online Order'}
              href='https://ordering.chownow.com/order/20479/locations'
              target='_blank'
              sx={{
                color: 'white',
                fontSize: [3, 2, 2],
                display: 'inline-block',
                marginX: 2,
                textTransform: 'uppercase',
                transition: 'all .3s',
                backgroundColor: 'primary',
                border: 'solid 2px',
                borderColor: 'primary',
                padding: '1rem',
                '&:hover': {
                  color: 'primary',
                  backgroundColor: 'transparent',
                  transition: 'all .3s',
                },
              }}>
              Online Order
            </ExternalLink> */}
          </Flex>
        </Box>
      </Box>

      {/* MOBILE NAVIGATION */}
      <Box sx={{ display: ['block', '', 'none'] }}>
        <MobileBottomNav
          data={{
            businessData: data.data.businessData,
            pageData
          }}
        ></MobileBottomNav>
      </Box>
    </>
  )
}

export default Navigation
