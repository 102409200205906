import React from 'react'
import { Box, Image, Flex, Link as ExternalLink } from 'theme-ui'
import Fade from 'react-reveal/Fade'
import MobileNavItem from './MobileNavItem'
import Logo from '../../assets/logo.png'

const MobileNavMenu = ({ localAvatar, withAvatar, navItems, businessData }) => {
  const { avatar, name } = businessData
  const renderLogo = () =>
    localAvatar ? (
      <Image sx={{ maxWidth: 175, mb: 3 }} src={Logo} alt={name}></Image>
    ) : (
      <Image sx={{ maxWidth: 250 }} src={`${avatar.imageBaseUrl}/${avatar.imagePrefix}`} alt={name} />
    )

  const renderMobileItems = () => navItems.map((itm, idx) => <MobileNavItem item={itm} idx={idx}></MobileNavItem>)

  const lineStyles = {
    width: '3px',
    height: 'auto',
    backgroundColor: 'primary',
    mr: 2
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        height: '100vh',
        width: '100%',
        zIndex: 99999,
        overflow: 'hidden',
        background: 'black',
        padding: 4
      }}
    >
      {withAvatar ? renderLogo() : ''}
      <Box>
        {renderMobileItems()}
        {/* ? Online Order Link */}
        <Fade left delay={7 * 100}>
          <Flex
            sx={{
              flexDirection: 'column',
              color: 'white',
              paddingBottom: 3,
              fontWeight: 'bold',
              textTransform: 'uppercase'
            }}
          >
            <Box as="span" sx={lineStyles} />

            <ExternalLink
              key={'Online Order'}
              href="https://www.toasttab.com/ponzaitaliankitchen"
              target="_blank"
              sx={{}}
            >
              Online Order
            </ExternalLink>
            <ExternalLink
              key={'Slice'}
              href="https://slicelife.com/restaurants/ct/norwalk/06854/ponza-italian-kitchen-pizza/menu?utm_campaign=order_now_button&utm_medium=referral&utm_source=ponzaitaliankitchen.com"
              target="_blank"
              sx={{
                color: 'white',
                fontSize: [3, 2, 2],
                marginX: '0!important',
                marginTop: '10px',
                textTransform: 'uppercase',
                transition: 'all .3s',
                '&:hover': {
                  color: 'primary',
                  transition: 'all .3s'
                }
              }}
            >
              Slice
            </ExternalLink>
          </Flex>
        </Fade>
        {/* ? Make reservation Link */}
        <Fade left delay={8 * 100}>
          <Flex
            sx={{
              color: 'white',
              paddingBottom: 3,
              fontWeight: 'bold',
              textTransform: 'uppercase'
            }}
          >
            <Box as="span" sx={lineStyles} />

            {/* <ExternalLink
              key={'Reservation'}
              href='https://www.opentable.com/r/johns-best-pizza-norwalk-reservations-norwalk?restref=1083586&lang=en-US&ot_source=Restaurant%20website'
              target='_blank'
              sx={{}}>
              Make a Reservation
            </ExternalLink> */}
          </Flex>
        </Fade>
      </Box>
    </Box>
  )
}

export default MobileNavMenu
