import React, { useState, useEffect } from 'react'
import AllIn from './allIn'
import Cells from './cells'
import Tabs from './tabs'
import TabsView from './tabsView'
import shortid from 'shortid'
import slugify from 'slugify'

const CellsAndAllIn = ({ menuData, setModalActive, isCellsAndTabs, mode }) => {
  const [activeSection, setActiveSection] = useState(null)
  const [displayedCellSection, setDisplayedCellSection] = useState()

  const onCellClick = selection => {
    const sectionClassName = () => slugify(selection.section.name, { lower: true })

    setTimeout(() => {
      try {
        const element = document.querySelector(`.${sectionClassName()}`)

        if (element) {
          const offset = 250
          const bodyRect = document.body.getBoundingClientRect().top
          const elementRect = element.getBoundingClientRect().top
          const elementPosition = elementRect - bodyRect
          const offsetPosition = elementPosition - offset

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          })
        }
      } catch (error) {
        console.log('could not scroll')
      }
    }, 500)
    setActiveSection(selection)
    return e => {
      e.preventDefault()
      setActiveSection(selection)
    }
  }

  const onBackClick = () => {
    setActiveSection(null)
  }

  useEffect(() => {
    if (activeSection && isCellsAndTabs) {
      setDisplayedCellSection({
        section: activeSection.inventory[0].section,
        inventory: activeSection.inventory[0].inventory
      })
    }
  }, activeSection)

  // const childrenWithProps = useMemo(() =>
  //   React.Children.map(props.children, child => {
  //     return React.cloneElement(child, {
  //       onBackClick,
  //       data: activeSection,
  //     })
  //   })
  // )

  const childrenWithProps = () => (
    <AllIn
      menuData={displayedCellSection ? displayedCellSection : activeSection}
      setModalActive={setModalActive}
      onBackClick={onBackClick}
    />
  )
  const tabsChildren = () => (
    <TabsView menuData={activeSection} setModalAcive={setModalActive} onBackClick={onBackClick} />
  )

  if (isCellsAndTabs) {
    return activeSection ? (
      <div>
        <Tabs
          key={shortid.generate()}
          menuData={activeSection}
          onCellClick={onCellClick}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          hasNestedTabs
          displayedCellSection={displayedCellSection}
          setDisplayedCellSection={setDisplayedCellSection}
          mode={mode}
        />

        {childrenWithProps()}
      </div>
    ) : (
      <Cells key={shortid.generate()} menuData={menuData} onCellClick={onCellClick} mode={mode} />
    )
  } else {
    return activeSection ? (
      <div>{childrenWithProps()}</div>
    ) : (
      <Cells key={shortid.generate()} menuData={menuData} onCellClick={onCellClick} mode={mode} />
    )
  }
}

export default CellsAndAllIn
