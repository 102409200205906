import React, { useState } from 'react'
import Price from './price'
import PriceWithVariants from './PriceWithVariants'
import Lightbox from 'react-image-lightbox'
import { Box, Image, Flex, Text } from 'theme-ui'
import { MenuContext } from './MenuContext'
// import LinkSelector from '../../Links/LinkSelector'

const MenuItem = ({ item, type, withDollar, hasMenuImages, menuItemIndex }) => {
  const context = React.useContext(MenuContext)
  const [lightBox, setLightbox] = useState({
    isOpen: false,
    mainSrc: ''
  })

  const getMenuItemType = () => {
    switch (type) {
      case 'someCase':
      default:
        return defaultType()
    }
  }

  // When copying a menu, for some reason the string URL gets a -copy attached at the end of it. This function removes it.
  const removeImageCopy = img => (img.includes('copy') ? img.substring(0, img.length - 5) : img)

  const imageCopy = item.imageUrl.includes('copy') ? removeImageCopy(item.imageUrl) : item.imageUrl

  const businessLogo = `${context.businessData.avatar.imageBaseUrl}/${context.businessData.avatar.imagePrefix}`

  const defaultType = () => (
    <Box variant={`${context.variantName}.menuItemInnerContainer`} className="menuItemInnerContainer">
      {lightBox.isOpen && (
        <Lightbox
          imageCaption={
            <Box sx={{ height: '25px' }}>
              <Text
                as="p"
                variant={`${context.variantName}.menuItemName`}
                className="menuItemName"
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}
              >
                {item.name}
              </Text>
            </Box>
          }
          mainSrc={removeImageCopy(lightBox.mainSrc)}
          onCloseRequest={() => setLightbox({ isOpen: false })}
        />
      )}

      <Box
        sx={{ cursor: `${item.photo_id ? 'pointer' : 'unset'}` }}
        variant={`${context.variantName}.menuItemImageContainer`}
        className="menuItemImageContainer"
        onClick={
          item.photo_id
            ? () =>
                setLightbox({
                  isOpen: true,
                  mainSrc: item.imageUrl
                })
            : () => ''
        }
      >
        <Box variant={`${context.variantName}.itemImageFill`} className="itemImageFill" />

        <Image
          sx={{
            cursor: `${item.photo_id ? 'pointer' : 'unset'}`,
            objectFit: `${item.photo_id ? 'cover' : 'contain'}`
          }}
          className={`${item.photo_id ? 'menuItemImg' : 'menuItemImgDefault'}`}
          variant={`${context.variantName}.${item.photo_id ? 'menuItemImg' : 'menuItemImgDefault'}`}
          src={item.photo_id ? imageCopy : businessLogo}
          alt="menu item"
        />
      </Box>

      <Flex variant={`${context.variantName}.menuItemContentContainer`} className="menuItemContentContainer">
        {item.variants.length === 1 && item.variants[0].label === '' ? (
          <Flex variant={`${context.variantName}.menuItemNamePriceContainer`} className="menuItemNamePriceContainer">
            <Text variant={`${context.variantName}.menuItemName`} className="menuItemName">
              {item.name}{' '}
            </Text>
            <Price withDollar={withDollar} variants={item.variants} toSide />
          </Flex>
        ) : (
          <Box variant={`${context.variantName}.menuItemNamePriceWithVariants`}>
            <Text variant={`${context.variantName}.menuItemName`} className="menuItemName">
              {item.name.toLowerCase() === 'catering menu' ? (
                <a
                  style={{ backgroundColor: 'red', padding: '1rem 1.4rem' }}
                  href="https://res.cloudinary.com/gonation/image/upload/v1683314193/sites/ponza/Catering_Menu_002.pdf"
                  target="_blank"
                >
                  Catering Menu PDF
                </a>
              ) : (
                item.name
              )}
            </Text>
            <PriceWithVariants withDollar={withDollar} variants={item.variants} toSide />
          </Box>
        )}
        {item.desc && (
          <Text variant={`${context.variantName}.menuItemDescription`} className="menuItemDescription">
            {item.desc}
          </Text>
        )}

        {/* {context?.options?.menuItemLinks &&
        context?.options?.menuItemLinks[menuItemIndex] ? (
          <LinkSelector
            variant={`${context.variantName}`}
            ctaName={context?.options?.menuItemLinkNames[menuItemIndex]}
            ctaLink={context?.options?.menuItemLinks[menuItemIndex]}
            isMultiLocationSite={context?.isMultiLocationSite}
            city={context?.businessData.city}
          />
        ) : (
          ''
        )} */}
      </Flex>
    </Box>
  )

  return (
    <Box variant={`${context.variantName}.${'menuItemContainer'}`} className={`${'menuItemContainer'}`}>
      {getMenuItemType()}
    </Box>
  )
}

export default MenuItem
