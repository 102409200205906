import React, { useEffect, useRef } from 'react';
import { Button, Box, Heading } from 'theme-ui';

import useApiRequest from '../../../hooks/useAPIRequest';
import Hero from '../../../components/Heros/Hero';
import HeroWrapper from '../../../components/Heros/HeroWrapper';

import SideBySideLarge from '../../../components/HomeComponents/sideBySideLarge';
import Instagram from '../../../components/Instagram/Instagram';
import Popup from '../../../components/Popup';

const Home = props => {
  const { error, isLoaded, data } = useApiRequest(props.endpoint[1].url);

  //   const openTableScript = useRef(null);

  //   useEffect(() => {
  //     const scriptVar = document.createElement('script');
  //     scriptVar.innerHTML = `var _ctct_m = "01c9ef9ae2e1e241be94fc9c6ca835de"; `;
  //     //change

  //     const constantContactScript = document.createElement('script');

  //     constantContactScript.src =
  //       '//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js';
  //     constantContactScript.async = true;
  //     constantContactScript.defer = true;

  //     document.body.appendChild(scriptVar);
  //     document.body.appendChild(constantContactScript);
  //   });

  //   useEffect(() => {
  //     const script = document.createElement('script');
  //     script.type = 'text/javascript';
  //     script.src =
  //       '//www.opentable.com/widget/reservation/loader?rid=1083586&type=standard&theme=standard&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website';
  //     script.async = true;

  //     openTableScript.current.appendChild(script);

  //     return () => {
  //       openTableScript.current.removeChild(script);
  //     };
  //   }, []);

  return (
    <>
      <Popup />
      <Hero
        heroData={{
          imageName: 'sites/ponza/ponza-hero',
          height: 'calc(75vh)',
          maxWidth: 2000,
        }}
      >
        <HeroWrapper>
          <Heading
            sx={{ fontSize: [5, 6, 6], fontWeight: 'bold', color: 'white' }}
          >
            A spirited neighborhood restaurant and gathering place.
          </Heading>
        </HeroWrapper>
      </Hero>

      <SideBySideLarge
        withShout
        shoutData={{
          error,
          isLoaded,
          data,
        }}
      ></SideBySideLarge>
      <SideBySideLarge reversed />
      <Instagram></Instagram>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '4rem 1rem',
        }}
      >
        {/* <Heading sx={headingStyles} as='h2'>
          Make A Reservation
        </Heading> */}
        {/* <div ref={openTableScript}></div> */}
      </Box>
    </>
  );
};

export default Home;

const headingStyles = {
  fontSize: '48px',
  marginBottom: '16px',
  fontWeight: '300',
};
