import React from 'react'
import splitSectionChildren from '../../helpers/splitSectionChildren'
import MenuTab from './menuTab'
import { Box } from 'theme-ui'
import { MenuContext } from './MenuContext'

const Tabs = ({
  menuData,
  onCellClick,
  children,
  activeSection,
  hasNestedTabs,
  displayedCellSection,
  setDisplayedCellSection
}) => {
  const { childSections } = splitSectionChildren(menuData)
  const context = React.useContext(MenuContext)

  const renderChildSections = () => {
    if (hasNestedTabs) {
      return activeSection.inventory.map(({ section, inventory }) => (
        <MenuTab
          section={section}
          inventory={inventory}
          onCellClick={onCellClick}
          activeSection={activeSection}
          displayedCellSection={displayedCellSection}
          hasNestedTabs
          setDisplayedCellSection={setDisplayedCellSection}
        />
      ))
    } else {
      return childSections.map(({ section, inventory }) => (
        <MenuTab section={section} inventory={inventory} onCellClick={onCellClick} activeSection={activeSection} />
      ))
    }
  }

  return (
    <Box variant={`${context.variantName}.tabsRow`} className="tabsRow">
      {
        // displayedCellSection
        // &&
        renderChildSections()
      }
      {children}
    </Box>
  )
}

export default Tabs
