import React, { useState, useContext } from 'react'
import { Box, Text, Flex } from 'theme-ui'
import useAPIRequest from '../../../hooks/useAPIRequest'

import Hero from '../../../components/Heros/Hero'
import Heading from '../../../components/Styled_UI/Heading'
import PhotoThumbnail from '../../../components/Gallery/PhotoThumbnail'
import GalleryLightbox from '../../../components/Gallery/GalleryLightbox'
import { render } from 'react-dom'
import GoNationContext from '../context/GoNationContext'

const CustomPage = () => {
  const ctx = useContext(GoNationContext)
  const { gonationID } = ctx.businessData
  const galleryEndpoint = ctx.dataEndpoints.filter(({ node }) => node.data.type === 'gallery')[0]
  console.log(galleryEndpoint.node.data.url)
  const { error, isLoaded, data } = useAPIRequest(galleryEndpoint.node.data.url.replace('{GONATION_ID}', gonationID))
  const [activeAlbum, setActiveAlbum] = useState([])
  const [lightbox, setLightbox] = useState({
    isOpen: false,
    photoIndex: 0
  })
  const linkStyle = {
    color: 'primary'
  }

  const handlePhotoClick = idx =>
    setLightbox({
      isOpen: true,
      photoIndex: idx
    })

  const renderPhotos = () =>
    data
      .filter(album => album.name === 'Private Events')[0]
      .photos.map((photo, idx) => (
        <Box sx={{ padding: 3, flex: ['1 1 100%', '1 1 50%', `1 1 25%`] }}>
          <PhotoThumbnail handlePhotoClick={handlePhotoClick} photo={photo} idx={idx} />
        </Box>
      ))
  const { isOpen, photoIndex } = lightbox
  const { photos } = activeAlbum
  return (
    <div>
      <Hero
        heroData={{
          imageName: 'sites/ponza/ponza-private-events-hero',
          height: 'calc(85vh - 246.75px)',
          maxWidth: 2000,
          mainTitle: ''
        }}
      ></Hero>
      <Box sx={{ paddingY: 4, paddingX: 3 }}>
        <Box sx={{ maxWidth: 800, margin: 'auto' }}>
          <Heading sx={{ color: 'dark', textAlign: 'center' }}>Private Events @ Ponza</Heading>
          <Text as="p" sx={{ mb: 3 }}>
            Enjoy multiple safe space private dining room options for your company meetings and events, networking
            receptions, and milestone celebrations.
          </Text>
          <Text as="p">
            Contact Ponza’s Event Coordinator{' '}
            <Text sx={linkStyle} as="a" href="tel: 203-807-6900">
              203-807-6900
            </Text>{' '}
            or EMAIL{' '}
            <Text sx={linkStyle} as="a" href="mailto: vbsandolo@gmail.com" target="_blank" rel="noopener noreferrer">
              vbsandolo@gmail.com
            </Text>
          </Text>
        </Box>
      </Box>
      <Box>
        {isLoaded && data && (
          <Flex sx={{ flexWrap: 'wrap', maxWidth: '1200px', margin: 'auto' }}>{renderPhotos()}</Flex>
        )}
      </Box>
      {isOpen && (
        <GalleryLightbox
          photos={data.filter(album => album.name === 'Private Events')[0].photos}
          photoIndex={photoIndex}
          setLightbox={setLightbox}
          lightbox={lightbox}
        />
      )}
    </div>
  )
}
//
export default CustomPage
